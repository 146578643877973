// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hadi-js": () => import("./../../../src/pages/hadi.js" /* webpackChunkName: "component---src-pages-hadi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pronounced-js": () => import("./../../../src/pages/pronounced.js" /* webpackChunkName: "component---src-pages-pronounced-js" */),
  "component---src-pages-stick-bugged-lol-js": () => import("./../../../src/pages/stick/bugged/lol.js" /* webpackChunkName: "component---src-pages-stick-bugged-lol-js" */),
  "component---src-pages-stuff-js": () => import("./../../../src/pages/stuff.js" /* webpackChunkName: "component---src-pages-stuff-js" */),
  "component---src-pages-writing-index-js": () => import("./../../../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */)
}

